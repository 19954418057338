import React from 'react';
import { Counter } from './features/counter/Counter';
import './App.css';
import Router from './component/Router'
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import { store } from "./app/store";

const theme = createTheme({
  palette: {
    mode: "dark",
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: "#fff"
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          background: "#D3D7DE",
          fill: "none",
        },
        colorSecondary: "#D3D7De"
      }
    }
  }
})

function App() {
  return (
    <ThemeProvider theme={ theme }>
      <CssBaseline/>
      <Router/>
    </ThemeProvider>
  );
}

export default App;
