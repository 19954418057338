import { Link } from "react-router-dom";
import styled from "styled-components";

type Prop = {
  url: string,
  name: string,
}

const StyledLink = styled(Link)`
  color: #000;
  text-decoration: none;
`
export const OtherLinkItem = (prop: Prop) => {
  return (
    <StyledLink target={"_blank"} to={prop.url} color={"#fff"} style={{fontSize: "14px"}}>{prop.name}</StyledLink>
  )
}