import { useState } from "react";
import { Button, Drawer, Icon, IconButton, Link, useMediaQuery } from "@mui/material";
import { Close, Menu } from "@mui/icons-material";
import styled from "styled-components";
import { ReservationButton } from "./ReservationButton";
import { LogoutButton } from "./LogoutButton";
import { OtherLinkItem } from "./OtherLinkItem";
import { HomeButton } from "./HomeButton";

const Header = styled.header`
  background: #353535;
  position: fixed;
  z-index: 100;
  width: 100%;
  padding-top: 2%;
  padding-bottom: 2%;
  margin-bottom: 30px;
  justify-content: end;
  align-items: center;
  height: 50px;
  display: flex;
  @media screen and (min-width: 768px) {
    justify-content: end;
  }

`

const Hamburger = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`
const HeaderItem = styled.div`
  margin-left: 20px;
  margin-right: 10px;
  margin-top: 10px;
`

type Props = {
  isLoggedIn: boolean
}
const AppHeader = ({ isLoggedIn }: Props) => {
  const [ drawerOpened, setDrawerOpened ] = useState(false)
  const isPC = useMediaQuery("(min-width:768px)")
  return (
    <Header>
      { isPC ? (
        <>
        </>

      ) : (
        <Hamburger>
          <IconButton
            onClick={ () => setDrawerOpened(true) }
            size={ "large" }
            sx={ {
              marginRight: 1,
              marginLeft: 0,
              background: "#464646",
              borderRadius: 0,
              height: 50,
              width: 50,
              marginY: 0,
            } }
            aria-label={ "menu" }
          >
            <Menu
              color={ "action" }
              sx={ { color: "#fff", width: "100%", height: "100%" } }
            />
          </IconButton>
          {/*<img*/ }
          {/*  src={}*/ }
          {/*  onClick={() => setDrawerOpened(true)}/>*/ }
          <Drawer
            anchor={ "left" }
            open={ drawerOpened }
            sx={ {
              opacity: 0.9,
              position: "fixed",
              zIndex: 120,
              top: 0,
              left: 0,
              width: "60%",
              height: "80vh",
              color: "#000",
              transition: "all 0.6s",
            } }
            onClose={ () => setDrawerOpened(false) }
          >
            <IconButton
              sx={ {
                width: "100%",
                textAlign: "left",
                justifyContent: "left",
                color: "#000"
              } }
            >
              <Close
                onClick={ () => setDrawerOpened(false) }
                fontSize={ "large" }
              />
            </IconButton>
            { isLoggedIn ? (
              <ReservationButton marginX={2}/>
            ) : (<Button sx={{marginY: 1}}/>) }
            { isLoggedIn ? (
              <LogoutButton marginLeft={2} marginRight={2}/>
            ) : (<Button sx={{marginY: 1}}/>) }
            <HeaderItem>
              <Link href={"/"} sx={{textDecoration: "none"}} color={"#000"}>HOME</Link>
            </HeaderItem>
            <HeaderItem>
              <Link href={"/training"} sx={{textDecoration: "none"}} color={"#000"}>過去のボデイを見る</Link>
            </HeaderItem>
            <HeaderItem>
              <Link href={"/measurement"} sx={{textDecoration: "none"}} color={"#000"}>部位別測定を見る</Link>
            </HeaderItem>
            <hr style={{ width: "80%", margin: "10px auto", border: "0.5px solid #ccc"}}/>
            <HeaderItem>
              <OtherLinkItem url={"#"} name={"マッスルフォトとは"}/>
            </HeaderItem>
            <HeaderItem>
              <OtherLinkItem url={ "#" } name={ "運営会社" }/>
            </HeaderItem>
            <HeaderItem>
              <OtherLinkItem url={ "#" } name={ "お問い合わせ" }/>
            </HeaderItem>
            <HeaderItem>
              <OtherLinkItem url={ "#" } name={ "サイトのご利用について" }/>
            </HeaderItem>
            <HeaderItem>
              <OtherLinkItem url={ "#" } name={ "プライバシーポリシー" }/>
            </HeaderItem>
            <HeaderItem>
              <OtherLinkItem url={ "#" } name={ "個人情報保護方針" }/>
            </HeaderItem>
          </Drawer>
        </Hamburger>
      ) }
      { isLoggedIn && !isPC ? (
        <HomeButton/>
      ) : ("") }

      { isLoggedIn ? (
        <ReservationButton marginX={2}/>
      ) : ("") }
      { isLoggedIn ? (
        <LogoutButton marginLeft={0} marginRight={2}/>
      ) : ("") }

    </Header>
  )
}
export default AppHeader