import { StyledDiv } from "../Home";
import { Box, Button, IconButton, ImageList, ImageListItem, ImageListItemBar, useMediaQuery } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchTrainingDetail, fetchTrainingImage } from "../../service/api";
import { TrainingDetail } from "../../domain/Training";
import loginBg2 from "../../assets/login_BG2.jpg";
import loginBody from "../../assets/login_body.png";
import { ArrowForwardIos, KeyboardDoubleArrowRight } from "@mui/icons-material";

interface MainImg {
  img: string,
  viewName: string
}

export const Detail = () => {
  const isPc = useMediaQuery("(min-width:768px)")
  const params = useParams<{ trainingId: string }>()
  const [ mainImg, setMainImg ] = useState<MainImg>()
  const [ trainingDetail, setTrainingDetail ] = useState<TrainingDetail>()
  useEffect(() => {
    const getDetail = async () => {
      try {
        if (typeof params.trainingId === 'undefined') return
        await fetchTrainingDetail(params.trainingId).then(async (res) => {
          const frontImage = await fetchTrainingImage(res.frontImageUrl)
          const sideLImage = await fetchTrainingImage(res.sideLImageUrl)
          const sideRImage = await fetchTrainingImage(res.sideRImageUrl)
          const backImage = await fetchTrainingImage(res.backImageUrl)
          setTrainingDetail({
            trainingId: res.trainingId,
            trainingDate: res.trainingDate,
            frontImageUrl: frontImage,
            sideLImageUrl: sideLImage,
            sideRImageUrl: sideRImage,
            backImageUrl: backImage
          })
          setMainImg({ img: frontImage, viewName: "FRONT" })
        })
      } catch (e) {
        console.log(e)
        alert(e)
      }
    }
    getDetail()
  }, [ params.trainingId ])
  return (
    <StyledDiv>
      <Box
        sx={ {
          background: "#fff",
          justifyContent: "center",
          textAlign: "center",
          paddingY: 2
        } }
      >
        <Box sx={ {
          width: "90%",
          marginX: "auto",
          position: "relative"
        } }>
          <img src={ mainImg?.img } width={ 150 }/>
          { !isPc && (
            <Box sx={{
              position: "absolute",
              top: 2,
              left: "30%",
              background: "#A39760",
              color: "#fff",
              width: 50,
              justifyContent: "center",
              fontSize: 10
            }}>
              { mainImg?.viewName }
            </Box>
          ) }
        </Box>
        <Box
          sx={ {
            width: "90%",
            marginX: "auto",
            textAlign: "center"
          } }
        >
          <ImageList cols={ 4 } gap={ 10 }>
            <ImageListItem key={ trainingDetail?.frontImageUrl } onClick={ () => {
              if (typeof trainingDetail?.frontImageUrl === "undefined") return
              setMainImg({ img: trainingDetail?.frontImageUrl, viewName: "FRONT" })
            } }>
              <img src={ trainingDetail?.frontImageUrl }
                   style={ { opacity: mainImg?.viewName === "FRONT" ? 1 : 0.5 } }/>
              { isPc && (
                <ImageListItemBar
                  position={ "below" }
                  sx={ {
                    background: "#A39760",
                    width: "80%",
                    textAlign: "center",
                    marginY: 2,
                    marginX: "auto",
                    color: "#fff",
                  } }
                  title={ "FRONT" }
                >
                </ImageListItemBar>
              ) }
            </ImageListItem>
            <ImageListItem key={ trainingDetail?.sideLImageUrl } onClick={ () => {
              if (typeof trainingDetail?.sideLImageUrl === "undefined") return
              setMainImg({ img: trainingDetail?.sideLImageUrl, viewName: "LEFT" })
            } }>
              <img src={ trainingDetail?.sideLImageUrl }
                   style={ { opacity: mainImg?.viewName === "LEFT" ? 1 : 0.5 } }/>
              { isPc && (
                <ImageListItemBar
                  position={ "below" }
                  sx={ {
                    background: "#A39760",
                    width: "80%",
                    textAlign: "center",
                    marginY: 2,
                    marginX: "auto",
                    color: "#fff",
                  } }
                  title={ "LEFT" }
                >
                </ImageListItemBar>
              ) }
            </ImageListItem>
            <ImageListItem key={ trainingDetail?.sideRImageUrl } onClick={ () => {
              if (typeof trainingDetail?.sideRImageUrl === "undefined") return
              setMainImg({ img: trainingDetail?.sideRImageUrl, viewName: "RIGHT" })
            } }>
              <img src={ trainingDetail?.sideRImageUrl }
                   style={ { opacity: mainImg?.viewName === "RIGHT" ? 1 : 0.5 } }/>
              { isPc && (
                <ImageListItemBar
                  position={ "below" }
                  sx={ {
                    background: "#A39760",
                    width: "80%",
                    textAlign: "center",
                    marginY: 2,
                    marginX: "auto",
                    color: "#fff",
                  } }
                  title={ "RIGHT" }
                >
                </ImageListItemBar>
              ) }
            </ImageListItem>
            <ImageListItem key={ trainingDetail?.backImageUrl } onClick={ () => {
              if (typeof trainingDetail?.backImageUrl === "undefined") return
              setMainImg({ img: trainingDetail?.backImageUrl, viewName: "BACK" })
            } }>
              <img src={ trainingDetail?.backImageUrl } style={ { opacity: mainImg?.viewName === "BACK" ? 1 : 0.5 } }/>
              { isPc && (
                <ImageListItemBar
                  position={ "below" }
                  sx={ {
                    background: "#A39760",
                    width: "80%",
                    textAlign: "center",
                    marginY: 2,
                    marginX: "auto",
                    color: "#fff",
                  } }
                  title={ "BACK" }
                >
                </ImageListItemBar>
              ) }
            </ImageListItem>
          </ImageList>
          <Link to={ "/training" } style={ { alignItems: "center", textDecoration: "none", color: "#A39760" } }>
            <KeyboardDoubleArrowRight sx={ { color: "#A39760" } } fontSize={ "small" }/>
            {/*<ArrowForwardIos sx={ { color: "#A39760" } } fontSize={ "small" }/>*/ }
            Back
          </Link>
        </Box>
      </Box>
      { isPc ? (
        <Box
          sx={ {
            width: "100%",
            backgroundImage: `url(${ loginBg2 })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            objectFit: "cover",
            paddingY: 10,
            display: "flex",
            justifyContent: "center"
          } }
        >
          <Link to={ "/measurement" } style={ { background: "#A39760" } }>
            <img src={ loginBody } width={ 450 }/>
          </Link>
        </Box>
      ) : (
        <Box sx={ {
          width: "90%",
          marginX: "auto",
          marginY: 3,
          display: "flex",
          textAlign: "center",
          justifyContent: "center"
        } }>
          <Link to={ "/measurement" }>
            {/*TODO SP用の画像を張り替える*/ }
            <img src={ loginBody } width={ 250 }/>
          </Link>
        </Box>
      ) }
      { isPc ? "" : (
        <Box
          sx={ {
            width: "100%",
            marginY: 0,
            paddingY: 0,
          } }
        >
          <img src={ loginBg2 } style={ { objectFit: "cover", objectPosition: "center" } } width={ "100%" }
               height={ 200 }/>
        </Box>
      ) }
    </StyledDiv>
  )
}