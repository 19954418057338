import { Box, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import { Image } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { OtherLinkItem } from "./OtherLinkItem";

const Footer = styled.footer`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  text-align: center;
  color: #fff;
  @media screen and (min-width: 768px) {
    width: 70%;
    max-width: 700px;
  }
`
const AppFooter = () => {
  const isPc = useMediaQuery("(min-width:768px)")
  return (
    <Footer>
      <Box sx={ isPc ? {
        width: "100%",
        marginTop: 5,
        display: "flex"
      } : {
        width: "100%",
        marginTop: 5,
      } }>
        { isPc ? (
          <Box>
            <Image/>
          </Box>
        ) : (<Image/>) }
      </Box>
      <Box component={ "div" } sx={ {
        width: "100%"
      } }>
        <Box
          sx={ {
            display: "flex",
            justifyContent: "center"
          } }
        >
          { isPc ? (
            <></>
          ) : (<Link to={ "#" } target={ "_blank" } style={ {
            fontSize: 12,
            color: "#fff",
            textDecoration: "none",
            padding: 5
          } }>運営会社</Link>) }
          { isPc ? (
            <></>
          ) : (<Link to={ "#" } target={ "_blank" } style={ {
            fontSize: 12,
            color: "#fff",
            textDecoration: "none",
            padding: 5
          } }>お問い合わせ</Link>) }
        </Box>
        <Box
          sx={ {
            display: "flex",
            justifyContent: "center"
          } }
        >
          { isPc ? (
            <></>
          ) : (<Link to={ "#" } target={ "_blank" } style={ {
            fontSize: 12,
            color: "#fff",
            textDecoration: "none",
            padding: 3
          } }>サイトのご利用について</Link>) }
          { isPc ? (
            <></>
          ) : (<Link to={ "#" } target={ "_blank" } style={ {
            fontSize: 12,
            color: "#fff",
            textDecoration: "none",
            padding: 3
          } }>プライバシーポリシー</Link>) }
          { isPc ? (
            <></>
          ) : (<Link to={ "#" } target={ "_blank" } style={ {
            fontSize: 12,
            color: "#fff",
            textDecoration: "none",
            padding: 3
          } }>個人情報方針</Link>) }
        </Box>
      </Box>
    </Footer>
  )
}
export default AppFooter