import { Button } from "@mui/material";
import { fetchLogout } from "../../service/api";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsLoggedIn, setPasswordInit } from "../../features/auth/authSlice";
import { useState } from "react";

type Props = {
  marginLeft: number,
  marginRight: number,
}

export const LogoutButton = ({marginRight, marginLeft}: Props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [ isError, setIsError ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState("")
  const logout = async () => {
    try {
      const res = await fetchLogout()
      if (res.result)
      await dispatch(setIsLoggedIn(false))
      await dispatch(setPasswordInit(false))
      navigate('/login')

    } catch (e: any) {
      setIsError(true)
      alert(e.message)
    }
  }
  return (
    <Button
      onClick={ logout }
      sx={ {
        background: "#AFA295",
        marginRight: marginRight,
        marginLeft: marginLeft,
        paddingRight: 1,
        color: "#535353",
        // width: "90%"
      } }
    >ログアウト</Button>
  )
}