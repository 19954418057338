import { configureStore, ThunkAction, Action, combineReducers, compose, createStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import { persistReducer } from 'redux-persist'
import authSlice from '../features/auth/authSlice'
import thunk from "redux-thunk";
import storage from 'redux-persist/lib/storage'

const reducers = combineReducers({
  counter: counterReducer,
  auth: authSlice
})

const persistConfig = {
  key: 'root',
  storage
}

const persistedReducer = persistReducer(persistConfig, reducers)
export const store = configureStore({
  reducer: persistedReducer,
  middleware: [ thunk ],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
