import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchLogin } from "../../service/api";
import { LoginResponse } from "../../domain/Response";
import { LoginRequest } from "../../domain/Request";
import { RootState } from "../../app/store";

export interface AuthState {
  isLoggedIn: boolean,
  passwordInit: boolean
}

const initialState: AuthState = {
  isLoggedIn: false,
  passwordInit: false,
}
export const authSlice = createSlice({
  name: "authSlice",
  initialState: initialState,
  reducers: {

    setIsLoggedIn: (state: AuthState, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload
    },
    setPasswordInit: (state: AuthState, action: PayloadAction<boolean>) => {
      state.passwordInit = action.payload
    }
  },
  // extraReducers: (builder) => {
  //   builder.addCase(login.fulfilled, (state: AuthState, action: PayloadAction<LoginResponse>) => {
  //     return {
  //       ...state,
  //       isLoggedIn: action.payload.result,
  //       passwordInit: action.payload.isPasswordInit
  //     }
  //   })
  //   builder.addCase(login.rejected, (state: AuthState) => {
  //     return {
  //       ...state,
  //       isLoggedIn: false,
  //       passwordInit: false
  //     }
  //   })
  // }
})


// export const login = createAsyncThunk(
//   "auth/login",
//   async (req: LoginRequest) => {
//     return await fetchLogin(req.email, req.password)
//   }
// )

export const getLoggedIn = (state: RootState) => state.auth.isLoggedIn
// export const getAuthInfo = (email:string, password: string) => {
//   return async (dispatch) => {
//     const response = await fetchLogin(email,password)
//     dispatch(setIsLoggedIn(response))
//   }
// }

export const { setIsLoggedIn, setPasswordInit} = authSlice.actions
export default authSlice.reducer