import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import AppHeader from "./common/AppHeader";
import AppFooter from "./common/AppFooter";
import { useDispatch, useSelector } from "react-redux";
import { AuthState, getLoggedIn } from "../features/auth/authSlice";
import { useEffect } from "react";

const mainStyle = `
  width: 100%;
`
const Main = styled.main`
  padding-top: 50px;
`
export const Layout = () => {
  return (
    <div className={mainStyle}>
      <AppHeader isLoggedIn={true}/>
      <Main>
        <Outlet/>
      </Main>
      <AppFooter/>
    </div>
  )
}