import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  FormControl,
  Grid,
  ImageList,
  ImageListItem, ImageListItemBar,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  useMediaQuery
} from "@mui/material";
import loginBG from "../../assets/login_BG.jpg"
import topSp from "../../assets/top_sp.jpg"
import loginBody from "../../assets/login_body.png"
import loginBg2 from "../../assets/login_BG2.jpg"
import { SubmitHandler, useForm } from "react-hook-form";
import { StyledDiv } from "../Home";
import dayjs, { Dayjs } from "dayjs";
import { DateFormat } from "../../domain/DateFormat";
import { Component, ReactFragment, useEffect, useState } from "react";
import { fetchGetTrainingMonth, fetchTrainingImage, fetchTrainings } from "../../service/api";
import { string } from "yup";
import { Training } from "../../domain/Training";
import { throws } from "assert";

export interface TrainingTargetMonthForm {
  yearMonth: string,
}

export const TrainingList = () => {
  const isPc = useMediaQuery("(min-width:768px)")
  const navigate = useNavigate()
  const [ yearMonthItems, setYearMonthItems ] = useState<string[]>([])
  const [ targetYearMonth, setTargetYearMonth ] = useState<string>()
  const [ trainingImages, setTrainingImages ] = useState<Training[]>([])
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    setLoading(true)

    async function getTrainingYearMonth() {
      try {
        const yearMonths = await fetchGetTrainingMonth()
        setYearMonthItems(yearMonths)
        setTargetYearMonth(yearMonths[0])
        const trainingRes = await fetchTrainings(yearMonths[0])
        if (typeof trainingRes.trainings === "undefined") return
        setTrainingImages([])
        const trainingItems = trainingRes.trainings.map(async (training) => {
          await fetchTrainingImage(training.thumbnailUrl).then((res) => {
            setTrainingImages([ ...trainingImages, {
              trainingId: training.trainingId,
              trainingDate: training.trainingDate,
              thumbnailUrl: res
            } ])
          })
        })
        // setTrainingImages(trainingRes.trainings)
      } catch (e) {
        alert(e)
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    getTrainingYearMonth()

  }, [])


  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm<TrainingTargetMonthForm>({
    defaultValues: {
      yearMonth: targetYearMonth
    }
  })
  const onSubmitTargetYearMonth: SubmitHandler<TrainingTargetMonthForm> = async (data) => {
    try {
      setTargetYearMonth(data.yearMonth)
      const res = await fetchTrainings(data.yearMonth)
      // resをimageListのItemに入れる
      const trainingItems = res.trainings.map((training) => {
        const thumbnailUrl = fetchTrainingImage(training.thumbnailUrl)
        return {
          trainingId: training.trainingId,
          trainingDate: training.trainingDate,
          thumbnailUrl: thumbnailUrl
        }
      })
      // setTrainingImages(trainingItems)
      // setTrainingImages(res.trainings)

    } catch (e) {
      alert(e)
    }
  }

  useEffect(() => {
    setLoading(true)

    async function getTrainings() {
      try {
        if (targetYearMonth === undefined) return
        const res = await fetchTrainings(targetYearMonth)
        res.trainings.forEach((training) => {
          fetchTrainingImage(training.thumbnailUrl).then((res) => {
            setTrainingImages([ ...trainingImages, {
              trainingId: training.trainingId,
              trainingDate: training.trainingDate,
              thumbnailUrl: res
            } ])
          })
        })
      } catch (e) {
        console.log(e)
        alert(e)
      } finally {
        setLoading(false)
      }
    }

    getTrainings()
  }, [ targetYearMonth ])

  if (loading) {
    return (<StyledDiv></StyledDiv>)
  }
  return (
    <StyledDiv>
      <Box width={ "100%" } textAlign={ "center" } bgcolor={ "#464646" }>
        <Select
          labelId={ "targetYearMonthLabel" }
          sx={ {
            width: 150,
            marginX: isPc ? 10 : "auto",
            marginTop: isPc ? 10 : 2,
            marginBottom: isPc ? 3 : 2,
            border: "none",
            background: "#fff",
            alignItems: "center"
          } }
          size={ "small" }
          defaultValue={ targetYearMonth }
          onChange={ (event) => {
            event.preventDefault()
            setTargetYearMonth(event.target.value)
          } }
        >
          { yearMonthItems.map((yearMonth) => {
            return (
              <MenuItem value={ yearMonth } sx={ { padding: 1 } }>{ yearMonth }</MenuItem>
            )
          }) }
        </Select>
      </Box>
      <Box sx={ {
        background: "#fff",
        color: "#000",
        width: "100%",
      } }>
        <ImageList sx={ { width: isPc ? "80%" : "90%", marginX: "auto",paddingY: 2 } } cols={ isPc ? 4 : 3 } gap={ isPc ? 4 : 1 }>
          { trainingImages === undefined ? "" : trainingImages.map((training) => {
            return (
              <ImageListItem key={ training.trainingId }>
                <Link to={ `/training/${ training.trainingId }` }>
                  <img src={ `${ training.thumbnailUrl }` } style={ { objectFit: "cover" } } width={ isPc ? 100 : 100 }/>
                  <ImageListItemBar
                    title={ training.trainingDate }
                    position={ "below" }
                  />
                </Link>
              </ImageListItem>
            )
          }) }
        </ImageList>
      </Box>
      { isPc ? (
        <Box
          sx={ {
            width: "100%",
            backgroundImage: `url(${ loginBg2 })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            objectFit: "cover",
            paddingY: 10,
            display: "flex",
            justifyContent: "center"
          } }
        >
          <Link to={ "/measurement" } style={ { background: "#A39760" } }>
            <img src={ loginBody } width={ 450 }/>
          </Link>
        </Box>
      ) : (
        <Box sx={ {
          width: "90%",
          marginX: "auto",
          marginY: 3,
          display: "flex",
          textAlign: "center",
          justifyContent: "center"
        } }>
          <Link to={ "/measurement" }>
            {/*TODO SP用の画像を張り替える*/ }
            <img src={ loginBody } width={ 250 }/>
          </Link>
        </Box>
      ) }
    </StyledDiv>
  )
}