import { KeyboardArrowRight, } from "@mui/icons-material";
import Header from "../../component/common/AppHeader";
import {
  Alert,
  Box, Button, FormHelperText, InputLabel, OutlinedInput, Snackbar,
  Stack, Typography,
  useMediaQuery
} from "@mui/material";
import AppFooter from "../../component/common/AppFooter";
import styled from "styled-components";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";
import { fetchGetUser, fetchLogin } from "../../service/api";
import { setIsLoggedIn, setPasswordInit } from "../../features/auth/authSlice";
import { useState } from "react";
import * as yup from 'yup'
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationMessage } from "../../domain/ValidationMessage";

const titleClass = { color: '#A39760' }
const LoginPage = styled.div`
  max-width: 100%;
  width: 100%;
`

export interface LoginForm {
  email: string,
  password: string
}

const Main = styled.main`
  padding-top: 80px;
  padding-bottom: 30px;
  width: 90%;
  margin: 0 auto;
  background: #fff;
  @media screen and (min-width: 768px) {
    //background-image: url("");
    width: 600px;
  }
`

const schemaRule = yup.object({
  email: yup.string()
    .required(ValidationMessage.required)
    .email(ValidationMessage.email),
  password: yup.string()
    .required(ValidationMessage.required)
    .min(8, ValidationMessage.min)
})

const Login = () => {
  const [ isError, setIsError ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState("")
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm<LoginForm>({
    resolver: yupResolver(schemaRule)
  })

  const onSubmit: SubmitHandler<LoginForm> = async (data: LoginForm) => {
    try {
      const res = await fetchLogin(data.email, data.password)

      if (res.result) {
        await dispatch(setIsLoggedIn(res.result))
        if (res.isPasswordInit) {
          await dispatch(setPasswordInit(res.isPasswordInit))
          navigate("/passwordInit")
        }
        navigate("/")
      }
    } catch (e: any) {
      setIsError(true)
      setErrorMessage(e.message)
    }
  }


  const isPC = useMediaQuery("(min-width:768px)")
  return (
    <>
      <Header isLoggedIn={ false }/>
      <Main>
        <Box component={ "div" } maxWidth={ "100%" } bgcolor={ "#fff" } paddingY={ 3 } color={ "#000" }
             marginX={ "auto" } width={ isPC ? 600 : "100%" }>
          <Typography component="h1" variant="h4" fontStyle={ titleClass } textAlign={ "center" }>
            LOGIN
          </Typography>
          <Stack
            component={ "form" }
            onSubmit={ handleSubmit(onSubmit) }
            width={ "90%" }
            marginX={ "auto" }
          >
            <Box marginY={ 2 } width={ "100%" } textAlign={ isPC ? "right" : "left" }
                 sx={ isPC ? { fontSize: 16 } : { fontSize: 14 } }>
              <InputLabel
                sx={ isPC ? { color: "#535353", display: "inline", fontSize: 16 } : { color: "#535353", fontSize: 14 } }
              >ID（メールアドレス）</InputLabel>
              <OutlinedInput
                { ...register('email') }
                type={ "email" }
                fullWidth={ !isPC }
                error={ "email" in errors }
                label={ "ID(メールアドレス)" }
                placeholder={ "メールアドレスかIDを入力してください" }
                sx={ isPC ? {
                    width: 320, background: "#D3D7DE", marginX: 2, fontSize: 16, color: "#535353"
                  }
                  : {
                    background: "#D3D7DE", fontSize: 14, color: "#535353"
                  } }
                size={ "small" }
                required={ true }
                aria-invalid={ errors.email ? "true" : "false" }
              />
              <FormHelperText sx={ { color: "red", textAlign: "center" } }>{ errors.email?.message }</FormHelperText>
            </Box>
            <Box marginY={ 2 } width={ "100%" } textAlign={ isPC ? "right" : "left" }
                 sx={ { fontSize: { xs: 14, md: 16 } } }>
              <label>パスワード</label>
              <OutlinedInput
                { ...register("password") }
                type={ "password" }
                error={ "password" in errors }
                placeholder={ 'パスワードを入力してください' }
                sx={ isPC ? {
                  width: 320, background: "#D3D7DE", marginX: 2, color: "#535353",
                } : { background: "#D3D7DE", color: "#535353", fontSize: 14, } }
                fullWidth={ !isPC }
                size={ "small" }
                required={ true }
                aria-invalid={ errors.password ? "true" : "false" }
              />
              <FormHelperText sx={ { color: "red", textAlign: "center" } }>{ errors.password?.message }</FormHelperText>
            </Box>
            <Button variant={ "contained" } type={ "submit" } sx={ {
              background: "#353535",
              color: '#A39760',
              width: 200,
              marginX: "auto",
              paddingY: 1,
              fontSize: 20,
              ":hover": {
                background: "#353535",
                opacity: 0.9
              }
            } }
            >
              LOGIN <KeyboardArrowRight sx={ { fontSize: 20, fontWeight: 100 } }/>
            </Button>
            <Snackbar
              open={ isError }
              autoHideDuration={ 6000 }
              onClose={ () => setIsError(false) }
            >
              <Alert
                severity={ "error" }
                onClose={ () => setIsError(false) }
                sx={ { width: '100%' } }
              >{ errorMessage }</Alert>
            </Snackbar>
          </Stack>
        </Box>
        <Box component={ "div" } sx={ { marginTop: 3, fontSize: 14, textAlign: "center" } } width={ isPC ? 600 : 300 }
             marginX={ "auto" }>
          <Box sx={ { width: "100%", marginTop: 2 } }>
            <Link to={ "/passwordForget" }
                  style={ { textAlign: "center", display: "inline-block" } }>パスワード再発行／忘れた方はこちら</Link>
          </Box>
          <Box sx={ { width: "100%", marginY: 2 } }>
            <Link to={ "#" } style={ { textAlign: "center", display: "inline-block" } }>初めての方はこちらから会員登録</Link>
          </Box>
        </Box>
      </Main>
      <AppFooter/>
    </>
  )
}

export default Login