import { Button } from "@mui/material";
import { Image } from "@mui/icons-material";

export const HomeButton = () => {
  const handleSubmit = () => {

  }
  return (
    <Button
      onClick={ handleSubmit }
      sx={ {
        marginRight: 0,
        marginLeft: 2,
        paddingRight: 1,
        color: "#fff",
        position: "absolute",
        left: 30,
        top: 7,
        // width: "90%"
      } }
    ><Image/></Button>
  )
}