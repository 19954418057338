import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AuthState, getLoggedIn, setIsLoggedIn } from "../features/auth/authSlice";
import { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import loginBG from "../assets/login_BG.jpg"
import topSp from "../assets/top_sp.jpg"
import loginBody from "../assets/login_body.png"
import loginBg2 from "../assets/login_BG2.jpg"
import { Image } from "@mui/icons-material";
import { fetchGetUser, fetchLogout } from "../service/api";
import styled from "styled-components";

export const StyledDiv = styled.div`
  background: #353535;
`

const Home = () => {
  const isPc = useMediaQuery("(min-width:768px)")
  const navigate = useNavigate()
  const [ userName, setUserName ] = useState("")
  useEffect(() => {
    async function getUser() {
      const res = await fetchGetUser()
      if (!res.data.isActive) {
        const logoutRes = await fetchLogout()
        if (logoutRes.result) {
          navigate("/login")
        }
      }
      setUserName(res.data.lastName)
      return res
    }

    getUser()
  }, [])
  return (
    <StyledDiv>
      <Box
        sx={ {
          backgroundImage: `url(${ loginBG })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          textAlign: "center",
          paddingY: 1,
          color: "#000"
        } }
      >
        <Box
          sx={ isPc ? {
            width: 500,
            background: "#fff",
            marginX: "auto",
            marginY: 3
          } : {
            width: "90%",
            background: "#fff",
            marginX: "auto",
            marginY: 1,
          } }
        >
          {/*TODO ロゴアイコンを追加する*/ }
          <Box
            sx={ {
              display: "flex",
              width: "90%",
              objectFit: "contain",
              marginX: "auto",
              paddingY: 2,
              alignItems: "center"
            } }
          >
            { isPc ? (
              <img src={ topSp } width={ 250 } height={ 250 }/>
            ) : (
              <img src={ topSp } width={ 150 } height={ 150 }/>
            ) }

            <p style={ { marginLeft: 20 } }>こんにちは{ userName }様</p>
          </Box>
        </Box>
      </Box>
      <Box sx={ {
        width: "90%",
        marginX: "auto",
        marginY: 3,
        display: "flex",
        textAlign: "center",
        justifyContent: "center"
      } }>
        <Link to={ "/training" }>
          {/*TODO SP用の画像を張り替える*/ }
          { isPc ? (<img src={ loginBody } width={ 350 }/>) : (<img src={ loginBody } width={ 180 }/>) }
        </Link>
        <Link to={ "/training" }>
          {/*TODO PC用の画像とSP用の画像を張り替える*/ }
          { isPc ? (<img src={ loginBody } width={ 350 }/>) : (<img src={ loginBody } width={ 180 }/>) }
        </Link>
      </Box>
      <Box
        sx={ {
          width: "100%",
          backgroundImage: `url(${ loginBg2 })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          objectFit: "cover",
          paddingY: 10
        } }
      >
      </Box>
    </StyledDiv>

  )
}

export default Home