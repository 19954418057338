import { Button, Icon } from "@mui/material";

type Props = {
  marginX: number
}
export const ReservationButton = ({marginX}: Props) => {
  return (
    <Button
      sx={ {
        border: "1px solid #f77060",
        background: "#f77060",
        color: "#535353",
        fontWeight: "bold",
        marginX: marginX,
        paddingRight: 1,
        marginY: 1
      } }
    >
      <Icon
        sx={ {
          borderRadius: 50,
          background: "#000",
          fontSize: 16,
          marginX: 0.5
        } }
      >check</Icon>
      <label>予約する</label>
    </Button>
  )
}